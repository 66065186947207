module directives {
    export module applicationcore {
        interface IMessageDisplayScope extends ng.IScope {
            messages: Array<interfaces.applicationcore.IMessage>,
            clearMessage(message: interfaces.applicationcore.IMessage): void
        }

        export class messageDisplayDirective implements ng.IDirective {
            restrict: 'E';
            template = `
            <div class="alert" ng-class="{'alert-success': message.TypeEnum === 3,
                                        'alert-warning': message.TypeEnum === 2,
                                        'alert-danger': message.TypeEnum === 1,
                                        'alert-info': !message.TypeEnum}" role="alert" ng-repeat="message in messages">
                {{message.LocaleDescription}}
                <button type="button" class="close" ng-click="clearMessage(message)" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
        `;
            scope = {
                messages: "=",
            }

            constructor() {
            }

            link = ($scope: IMessageDisplayScope, $element: ng.IAugmentedJQuery) => {
                var controller = $element.parent().controller();

                $scope.clearMessage = (message: interfaces.applicationcore.IMessage) => {
                    _.remove($scope.messages, (n) => {
                        return n === message;
                    });
                }
            }

            static factory(): ng.IDirectiveFactory {
                const directive = () => new messageDisplayDirective();
                directive.$inject = [];

                return directive;
            }
        }

        angular.module("app").directive("gtsMessageDisplay", messageDisplayDirective.factory());
    }
}